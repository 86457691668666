import ProductTile from "../islands/ProductTile.tsx";

function SearchModal(
    {
        handleOverlayClick,
        search,
        setSearch,
        searchedProducts,
        setShowNotification,
        setNotificationType,
        setMessage,
        handleShow,
    }: any,
) {
    return (
        <>
            <div
                id="overlay"
                className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-start z-50 pt-4"
                onClick={handleOverlayClick}
            >
                <div className="bg-white text-light-blue-900 p-6 rounded-md shadow-lg max-w-3xl w-full">
                    <input
                        type="text"
                        className="w-full p-3 rounded-md border border-light-blue-300 focus:outline-none focus:ring-2 focus:ring-light-blue-500"
                        placeholder="Sök..."
                        value={search}
                        onChange={(e: any) => setSearch(e.target.value)}
                        onInput={(e: any) => setSearch(e.target.value)}
                    />
                    <div class="px-2">
                        <ProductTile
                            products={searchedProducts}
                            setShowNotification={setShowNotification}
                            setNotificationType={setNotificationType}
                            setMessage={setMessage}
                            handleShow={handleShow}
                            isSearch={true}
                            search={search}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default SearchModal;

