import { useEffect, useState } from "preact/hooks";
import POP from "./POP.tsx";
import Notification from "../components/ToastNotifications/index.tsx";
import axiod from "https://deno.land/x/axiod@0.26.2/mod.ts";
import { DataTableSchema } from "../database/products/schema.ts";
import SearchModal from "../components/SearchModal.tsx";

const Header = () => {
  const [show, setShow] = useState(false);
  const [cartItemsCount, setCartItemsCount] = useState(0);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [message, setMessage] = useState("");
  const [cat, setCat] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [searchedProducts, setSearchedProducts] = useState<DataTableSchema[]>(
    [],
  );

  const toggleSearch = () => {
    setShowSearch(!showSearch);
    setSearch("");
  };

  const getSearchedData = async () => {
    try {
      const response = await axiod.get("/api/search/products", {
        params: {
          search: search,
        },
      });
      if (response.status === 200) {
        setSearchedProducts(response.data.products);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getSearchedData();
  }, [search]);

  const handleOverlayClick = (e: any) => {
    if (e.target.id === "overlay") {
      setShowSearch(false);
    }
  };

  const closeNotification = () => {
    setShowNotification(false);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getCat = async () => {
    try {
      const response = await axiod.get("/api/categories");
      setCat(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCat();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const cartItems = JSON.parse(localStorage.getItem("cart") || "[]");
      const totalQuantity = cartItems.reduce(
        (total: any, item: any) => total + item.quantity,
        0,
      );
      setCartItemsCount(totalQuantity);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Notification
        showNotification={showNotification}
        closeNotification={closeNotification}
        notificationType={notificationType}
        message={message}
      />
      <header>
        <div className="container px-0">
          <div className="flex justify-between p-0 items-center">
            <div className="navp">
              <a href="/">
                <img src="/logo.png" alt="logo" className="w-full h-14" />
              </a>
            </div>
            <div className="navp">
              <ul className="nav-bar flex !pr-0">
                <li>
                  <a href="/">HEM</a>
                </li>
                <li>
                  <a href="/info">INFO</a>
                </li>
                {cat && cat.map((c: any) => {
                  return (
                    <li>
                      <a class="uppercase" href={`/${c.name}`}>{c.name}</a>
                    </li>
                  );
                })}
                <li className="search-ic" onClick={toggleSearch}>
                  <img
                    src="/search.png"
                    alt="search"
                    className="w-5 h-5 cursor-pointer"
                  />
                </li>
                <li className="cart-ic">
                  <img
                    src="/cart.png"
                    alt="cart"
                    className="w-5 h-5"
                    onClick={handleShow}
                  />
                  <span className="count">{cartItemsCount || 0}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      {show && (
        <POP
          handleClose={handleClose}
          setNotificationType={setNotificationType}
          setShowNotification={setShowNotification}
          setMessage={setMessage}
        />
      )}
      {showSearch && (
        <SearchModal
          showSearch={showSearch}
          handleOverlayClick={handleOverlayClick}
          search={search}
          setSearch={setSearch}
          searchedProducts={searchedProducts}
          setShowNotification={setShowNotification}
          setNotificationType={setNotificationType}
          setMessage={setMessage}
          handleShow={handleShow}
        />
      )}
    </>
  );
};

export default Header;

